$maxWidth: 1000px;

$black: #181824;
$coal: #2d2d2d;
$dark: #8c9192;
$gray: #c2c6ca;
$light: #eeeeee;

$mainColor: #18171c;
$accentColor: #05003a;
$green: #00dc78;

$fa: 'FontAwesome';

@mixin center($maxWidth){
	width: 100%;
	margin: auto;
	position: relative;
	max-width: $maxWidth;
}

// Network Dropdowns
.edit_mode #networkDropMenu { z-index: 99; }

#networkDropMenu {
	height: 35px;
	background: $black;
	position: relative;
	z-index: 999;
	border-bottom: solid 1px $coal;

	.bar {
		@include center($maxWidth);
		position: relative;
	}

	#dropBtn {
		height: 35px;
		line-height: 35px;
		color: white;
		display: block;
		font-family: $fontTwo;
		font-size: 12px;
		color: $gray;
		float: left;
		transition: $transition-standard;
		font-weight: 600;
    letter-spacing: .025rem;

		&:after {
			content: '\f107';
			display: inline-block;
			margin-left: 5px;
			font-family: $fa;
		}

		&.opened {
			color: $green;

			&:after { content: '\f106'; }

		}

		&:hover { cursor: pointer; }

	}

	.menuWrap {
		@include center($maxWidth);
		top: 35px;
	}

	.networkDropTeams {
		position: absolute;
		padding: 30px 30px 10px 30px;
		background: white;
		top: 0;
		left: 0;
		border: solid 1px $gray;
		border-top: none;
		width: 100%;
		display: none;

		&:before {
			content: '';
			display: none;
			position: absolute;
			height: 55%;
    	width: 1px;
    	background: $light;
    	left: 49%;
    	top: 15%;
		}

		&:after {
			content: '';
			display: inline-block;
			position: absolute;
			right: 15px;
			top:0;
			height: 30px;
			width: 60px;
			background: #181824 url('http://assets.ngin.com.s3.amazonaws.com/site_files/11666/_site/images/logo.svg') center no-repeat;
			background-size: auto 80%;
		}

		h1 {
			font-family: $fontOne;
			font-size: 1.5rem;
			font-weight: 600;
			color: $mainColor;
			width: 49%;
			float: left;
			margin-bottom: 25px;

			&.westTitle {
				margin-right: 2%;
			}

		}

		.pageElement.heroPhotoElement {
			margin: 0 0 20px 0;
			float: left;
			clear: none;

			.cutline {
				font-family: $fontTwo;
				color: $coal;
				font-style: normal;
				line-height: 15px;
				font-size: 12px;
				padding: 10px 0 0 65px;
			}

		}

		.expansion {
			.pageElement.heroPhotoElement { width: auto; }
		}

		a {
			height: 45px;
			width: 60px;
			float: left;
			transition: $transition-standard;
			word-wrap: normal;
			color: #181824;

			&:hover {
				opacity: .75;
				text-decoration: none;
			}

			img {
				height: 100%;
				width: auto;

				&[src*='Harrisburg'] { width: 100%; }

			}

		}

		.west,
		.east {
			width: 49%;
			float: left;
		}

		.west { margin-right: 2%; }

		// Expansion
		.expansionTitle {
			clear: both;
			border-top: solid 1px $light-gray;
			padding-top: 15px;
			width: 100%;
		}

		.expansion {
			clear: both;
			width: 100%;
		}

	}

}

// Team Logo Container
#teamLogoContainer {
  display: none;
  position: relative;
  background: white;
  width: 100%;
  border-bottom: solid 4px $gray;
  padding: 15px 10%;

  &:after {
		content: '';
		display: inline-block;
		position: absolute;
		right: 0;
		top:0;
		height: 25px;
		width: 50px;
		background: #181824 url('https://assets.ngin.com/site_files/11666/_site/images/logo.svg') center no-repeat;
		background-size: auto 80%;
	}

  h1 {
    font-family: $fontOne;
    font-size: 1.5rem;
    font-weight: 600;
    color: #18171c;
    margin: 15px 0 10px;
  }

  .teamLogos {

    a {
      width: 50%;
      display: inline-block;
      height: 100px;
      text-align: center;
      padding: 5%;
      border-bottom: solid 1px $light;
      border-right: solid 1px $light;

      &:first-of-type,
      &:nth-of-type(2) { border-top: solid 1px $light; }

      &:nth-of-type(odd) { border-left: solid 1px $light; }

      img { height: 100%; }

    }

  }

}

// teams Menu
#teamsMenu {
  height: 44px;
  width: 80px;
  float: right;
  position: relative;
  margin-right: 10px;
  display: none;
  line-height: 40px;
	margin-right: 15px;
	z-index: 0;
  position: absolute;
 	right: 0;
  top: 0;

  // .logged_in & { margin-right: 120px; }

  &:after {
    content: 'USL Network';
    color: white;
    width: 60px;
    height: 50px;
    display: inline-block;
    bottom: 5px;
    right: 0;
    text-align: center;
    font-size: 10px;
    font-family: $fontTwo;
  }

  &:before {
  	content: '\f107';
  	font-family: $fa;
  	display: inline-block;
  	margin-right: 5px;
  	color: white;
  }
}
// Team Logos Page
#team-logos {

	.networkLogos {

		.pageElement.heroPhotoElement {

			img { max-width: 100px; }

		}

	}

}

.teamLogos {
	display: flex;
	flex-wrap: wrap;
}

.menuWrap .networkDropTeams .pageElement.heroPhotoElement {
	width: auto;
	min-width: 20%;
}

&.has-mobile-nav .menuWrap .networkDropTeams {
	padding-top: 30px;

	.teamLogos .pageElement { width: 50%; }

}

// Responsive
@media screen and (max-width: 1075px) {

	#networkDropMenu #dropBtn { padding-left: 15px; }

}

@media screen and (min-width: 1024px){
	#teamLogoContainer { display: none !important; }
}

@media (max-width: 1024px) and (min-width: 768px){
	#teamLogoContainer .teamLogos a { padding:3%; }
}

@media (max-width: 768px) and (min-width: 640px){
	#teamLogoContainer .teamLogos a {padding: 5%; }
}

@media screen and (max-width: 1024px) {

	#networkDropMenu { display: none; }

	#teamsMenu { display: block !important; }

}

//Page Search Styles Fix

div#PageSearchBoxTopNav {
	float: right;
	position: relative;
	top: 3px;
}

.usl-link {
  position: absolute;
  top: 0px;
  right: 15px;
  width: 60px !important;
  height: 30px !important;
  display: block;
  z-index: 1;
}
